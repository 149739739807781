/* For colors see : http://www.color-hex.com/color-palette/
  This only displays an overview of the colors we use */


/* UPALA */

.UnusedStyleToShowColors { 
  background: linear-gradient(to top,  #333399, #40268c, #4d1a80, #490d73,
                                       #410066, #7070b7,
                                       #f5f5ff, #f1d923, #f0150a, #9c27b0);
}

:root {
  --global-text-color: #030F03;
  --global-highlight-color: #124d12;
  --global-highlight-light: #8bc34a;
  --global-background-light: #e9f5e9;
  --global-background-very-light: #ffffff;
}


/* Home: Golden Pheasant */
/*
.UnusedStyleToShowColors {
    background: linear-gradient(to top, #feef29, #fda834, #67b3c2, #d42f24, #233269 #333399, #40268c, #4d1a80, #490d73, #410066, #7070b7, #f5f5ff, #f1d923, #f0150a, #9c27b0);
}

:root {
    --global-text-color: #d42f24;
    --global-highlight-color: #096d1f;
    --global-highlight-light: #fda834;
    --global-background-light: #eff7f8;
    --global-background-very-light: #ffffff;
}
*/

html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
body {
    font-family: "Fira Sans", "Roboto", "Helvetica", "Arial", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    color: var(--global-highlight-color);
    font-weight: 400;
}

h6 {
    font-size: 1.1em;
    color: var(--global-text-color);
    font-weight: 300;
    font-style: italic;
}

body {
    background-color: var(--global-background-very-light);
    margin: auto;
    line-height: 1.6em;
    font-weight: 300;
    color: var(--global-text-color);
}

strong {
    font-weight: 500;
}

img {
    width: 100%;
    border: 1px solid var(--global-highlight-light);
}

iframe {
    align-self: center;
}

@media (max-width: 991px) {
    body,
    html {
        position: relative;
        overflow-x: hidden;
    }
}