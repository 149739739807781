.NavigationItem {
    margin: 2px 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    text-align: left;
}

.NavigationItem a {
    color: var(--global-text-color);
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    border-bottom: 1px solid transparent;
    /* Avoid a movement of the page below when the bottom border is shown*/
}

.NavigationItem a:hover, .NavigationItem a.active {
    color: var(--global-highlight-color);
}

@media (min-width: 500px) {
    .NavigationItem {
        margin: 0px 0px;
        width: auto;
    }
    .NavigationItem a, .NavigationItem a:link, .NavigationItem a.visited {
        padding: 0.3em 0.5em;
    }
    .NavigationItem a:hover, .NavigationItem a:active, .NavigationItem a:focus {
        border-bottom: 1px solid var(--global-highlight-color);
    }
}

@media (min-width: 850px) {
    .NavigationItem {
        text-align: center;
    }
}