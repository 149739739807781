.Post {
    width: 100%;
    margin: 0px auto;
}
  
.Title, h1 {
    color: var(--global-highlight-color);
    text-align: left;
    font-size: 1.8em;
    font-weight: 500;
}

h2 {
    color: var(--global-highlight-color);
    font-size: 1.2em;
    font-weight: 400;
}

.SubCard {
    font-size: 1.0em;
    margin : 1.0em;
    margin-top: 1.6em;
    width: 90%;
    text-align: left;  
}

.Desc, h3 {
    font-size: 1.2em;
    font-style: italic;
    margin: 0.2em 0em 0.6em;
}
  
.Body {
      text-align: left;
      color: var(--global-text-color);  
}  

/* Side Buttons */
.SideButtons {
	position: absolute;
    display: flex;
    flex-direction: column;
	top: 240px;
    right: 0;
    list-style: none;
    z-index: 999;
}

/* .SideButtons li a { background: rgba(0, 0, 0, .2); } */

.SideButtons li a img {
	max-width:40px;
    -webkit-transition:  background .2s ease-in-out;
    -moz-transition:  background .2s ease-in-out;
    -o-transition:  background .2s ease-in-out;
    transition:  background .2s ease-in-out;
}

.SideButtons li a:hover img {background: rgba(0, 0, 0, 0);}

.PostInfo {
    display: flex;
    flex-flow: row wrap; 
    padding: 0px 32px 12px 0px;
    text-align: left;
    cursor: pointer;
}

.PostInfo a {
    margin-left: 3px;
    text-decoration: none;
    color: var(--global-text-color);
}

.PostInfo a:hover, .PostInfo a:active {
    text-decoration:underline;
}

.Posts {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    /* width: 95%; */
    /* margin: auto; */
    padding: 1em;
}

.Books {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin: auto;
    padding: 1em 1em 0em 0em;
}

.Book {
    margin: 2em 0em;
}

.PostCard {
    width: 280px;
    margin: 1em 1em 1em 1em;
    
}

/* Side Buttons */
.SideButtons {
	position: absolute;
    display: flex;
    flex-direction: column;
	top: 60px;
    right: 0px;
    list-style: none;
    z-index: 99;
}

.SideButtons li a img {
	max-width:40px;
    -webkit-transition:  background .2s ease-in-out;
    -moz-transition:  background .2s ease-in-out;
    -o-transition:  background .2s ease-in-out;
    transition:  background .2s ease-in-out;
}

.SideButtons li a:hover img {background: rgba(0, 0, 0, 0);}

@media (min-width: 600px) {
    .Books {
        width: 85%;
        padding: 2em 2em;
    }
}

@media (min-width: 850px) {
    .SubCard {
        width: 800px;
        margin: auto;
    }
}

@media (min-width: 1700px) {
}

