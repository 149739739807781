 .UserBox {
	position: static;
    text-align: left;
    margin: 0px 2em;
    padding: 0px;
}

.UserInfo {
    margin: 8px 0px 0px 8px ;
    color: #444;
    font-size: 1em;
}

.AllBtnBox{
    position: absolute;
    padding: 0px 1em 0px 0px; 
    margin: 0px 1em 0px 0px; 
    width:94%;
}

.EditUserBtns {
    margin: 0px 0px 0px -8px; 
    padding: 0px 16px; 
    display: flex;
    flex-direction: row-reverse;
    list-style: none;
    z-index: 99;
    -webkit-margin-before: 0em;
}

div.SingleBtnBox {
    margin: 0px 0.2em; 
    padding: 0px 0px; 
    -webkit-transition:  background .2s ease-in-out;
    -moz-transition:  background .2s ease-in-out;
    -o-transition:  background .2s ease-in-out;
    transition:  background .2s ease-in-out;
    opacity: 0.1;
}

div.SingleBtnBox:hover {
    opacity: 0.6;
}
