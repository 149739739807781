
.AnonBody {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: auto;
    /* min-height: 100vh; */

    /* background: url(/files/images/tract-4.6.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; */
}

.FormTip,
.FormTip a:link,
.FormTip a:visited,
.FormTip a:hover,
.FormTip a:active,
.FormTip a:focus {
  outline: 0;
  color: #a94c40;
}

.FormBox {
    flex: 1;
    opacity: 0.8;
    margin: auto;
    padding: 1em;
    margin: 4em 2em 2em 2em;
}

.Form {
    background-color: #f5f5ff;
    padding: 0.5em;
}

.OuterBox {
    display: table;
    margin: 2em auto;
}

.RegisterForm {
    height: auto;
    width: 100%;
	margin: auto;
	background: rgb(243, 243, 243);
}

.AuthForm {
    min-width: 320px;
    margin: auto;
}

.FormHeader {
    padding: 1em;
}

@media (min-height: 500px) {
    .OuterBox {
        padding: .5em 2em 0em 0em;
    }
}

@media (max-width: 475px) {
    .AuthForm {
        width: 80%;
    }
}

@media (min-width: 600px) {
    .Auth {
        width: 500px;
    }
}